<template>
  <div class="company-container">
    <!-- navbar -->
    <qb-navbar title="公司介绍"></qb-navbar>
    <!-- title -->
    <qb-title
      title="泉邦集团"
      subtitle="Quanbang Group Introduction"
    ></qb-title>
    <!-- empower-video -->
    <company-video></company-video>
    <!-- company-lecturer -->
    <company-lecturer></company-lecturer>
    <!-- company-news -->
    <company-news></company-news>
    <!-- company-cooperation -->
    <CompanyCooperation></CompanyCooperation>
  </div>
</template>

<script>
import CompanyVideo from './components/company-video'
import CompanyNews from './components/company-news'
import CompanyCooperation from './components/company-cooperation.vue'
import CompanyLecturer from './components/company-lecturer.vue'
export default {
  name: 'Company',
  components: {
    CompanyVideo,
    CompanyNews,
    CompanyCooperation,
    CompanyLecturer
  }
}
</script>

<style lang="less" scoped>
.company-container {
  overflow: hidden;
}
</style>
