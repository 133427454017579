<template>
  <div class="company-video-container">
    <div class="video">
      <video
        src="https://0.rc.xiniu.com/g3/M00/08/98/CgAH513DbbOAAIpsAawewRzESN4234.mp4"
        poster="@/assets/images/video_cover.png"
        loop="loop"
        ref="video"
        :controls="controls"
        @click="handlePlay(false)"
      ></video>
    </div>
    <div class="play_btn" @click="handlePlay(true)" v-if="showPlayBtn">
      <img src="@/assets/images/fission/play_btn.png" alt="" />
    </div>
    <div class="introduction">
      2016年8月，上海泉邦网络科技有限公司正式成立，总部设于“中国经济中心上海”，由互联网+商学教育的领军者马煜超老师，携手知名企业咨询师，实战企业家，行业大咖等合力创办。
    </div>
    <ul class="strength">
      <li style="marginBottom:8px">
        <div class="num" style="marginRight:30px">4<span>大</span></div>
        <div class="num">5<span>家</span></div>
      </li>
      <li style="marginBottom:39px">
        <div class="name">营运中心</div>
        <div class="name">省级分公司</div>
      </li>
      <li style="marginBottom:8px">
        <div class="num">96<span>家</span></div>
        <div class="num">200<span>位</span></div>
      </li>
      <li>
        <div class="name">合伙人</div>
        <div class="name">城市分公司</div>
      </li>
    </ul>
    <div class="company-cover">
      <van-image
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0e5f2e0c46bbf4573b52dd1d2f1f2f8e114e0f998a8db86dc177051ca82ba86f"
        width="100%"
        height="100%"
      >
      </van-image>
    </div>
  </div>
</template>

<script>
export default {
  name: 'companyVideo',
  data() {
    return {
      showPlayBtn: true,
      controls: false
    }
  },
  methods: {
    handlePlay(flag) {
      if (flag) {
        this.$refs.video.play()
        this.controls = true
        this.showPlayBtn = false
        return
      }
      this.$refs.video.pause()
      this.showPlayBtn = true
      this.controls = false
    }
  }
}
</script>

<style lang="less" scoped>
.company-video-container {
  padding-top: 84px;
  position: relative;
  .video {
    width: 335px;
    height: 182px;
    margin: 0 auto;
    video {
      object-fit: cover;
      width: 100%;
    }
  }
  .play_btn {
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    width: 26px;
    height: 26px;
  }
  .introduction {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 19px 21px;
  }
}
.strength {
  li {
    display: flex;
    justify-content: space-evenly;
    .num {
      font-size: 24px;
      font-weight: normal;
      color: #196de6;
      line-height: 34px;
      span {
        font-size: 14px;
        font-weight: 400;
        color: #196de6;
        line-height: 20px;
        margin-left: 5px;
      }
    }
    .name {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
.company-cover {
  width: 335px;
  height: 149px;
  margin: 31px auto;
}
</style>
