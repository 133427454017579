<template>
  <div class="news-container">
    <van-tabs v-model="active">
      <van-tab title="公司新闻">
        <qb-news-item
          v-for="item in newList"
          :key="item.id"
          :item="item"
          @click.native="$router.push(`/news/detail/${item.id}`)"
        ></qb-news-item>
      </van-tab>
      <van-tab title="媒体动态"
        ><qb-news-item
          v-for="item in newList"
          :key="item.id"
          :item="item"
          @click.native="$router.push(`/news/detail/${item.id}`)"
        ></qb-news-item
      ></van-tab>
      <van-tab title="启动大会"
        ><qb-news-item
          v-for="item in newList"
          :key="item.id"
          :item="item"
          @click.native="$router.push(`/news/detail/${item.id}`)"
        ></qb-news-item
      ></van-tab>
    </van-tabs>
    <div class="line"></div>
    <QbMore
      top="7"
      bottom="18"
      class="more"
      @click.native="$router.push('/news/news')"
    ></QbMore>
  </div>
</template>

<script>
import QbMore from '@/components/qb-more.vue'
export default {
  name: 'MainCourse',
  components: {
    QbMore
  },
  data() {
    return {
      active: 0,
      newList: [
        {
          id: 1,
          title: '泉邦集团董事长马煜超受清华大学研5435345435究生院邀请',
          time: '2020-04-30',
          introduction:
            '12月1日，泉邦集团45345435435董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲…'
        },
        {
          id: 2,
          title: '泉邦集团董事长马煜超受清华大学研5435345435究生院邀请',
          time: '2020-04-30',
          introduction:
            '12月1日，泉邦集团45345435435董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲…'
        },
        {
          id: 3,
          title: '泉邦集团董事长马煜超受清华大学研5435345435究生院邀请',
          time: '2020-04-30',
          introduction:
            '12月1日，泉邦集团45345435435董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲…'
        },
        {
          id: 4,
          title: '泉邦集团董事长马煜超受清华大学研5435345435究生院邀请',
          time: '2020-04-30',
          introduction:
            '12月1日，泉邦集团45345435435董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲…'
        },
        {
          id: 5,
          title: '泉邦集团董事长马煜超受清华大学研5435345435究生院邀请',
          time: '2020-04-30',
          introduction:
            '12月1日，泉邦集团45345435435董事长马煜超受清华大学深圳研究生院培训学院邀请，于南山西丽大学城清华学思楼开讲…'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.news-container {
  position: relative;
  padding-top: 20px;

  .line {
    width: 375px;
    position: absolute;
    border-bottom: 1px solid #1651dd;
    top: 49px;
  }
  ::v-deep .van-tabs__wrap {
    height: 30px;
    .van-tabs__nav {
      justify-content: space-evenly;
    }
    .van-tab {
      padding: 0;
      flex: none;
    }
    .van-tabs__line {
      width: 96px;
      height: 4px;
      background: #1651dd;
    }
    .van-tab__text {
      font-size: 18px;
    }
    .van-tab--active {
      font-size: 18px;
      font-weight: 700;
      color: #010343;
    }
  }
  ::v-deep .van-tabs__content {
    padding-top: 7px;
  }
  .more {
    margin-top: 22px !important;
  }
}
.swiper {
  width: 375px;
  height: 462px;
  img {
    width: 100%;
  }
  ::v-deep .van-swipe__indicators {
    .van-swipe__indicator {
      position: relative;
      width: 6px;
      height: 6px;
      border: 1px solid #000;
      background-color: #fff;
      opacity: 1;
    }
    i.van-swipe__indicator--active {
      width: 12px;
      height: 12px;
      background: #000;
      border: 1px solid #000;
      transform: translateY(-20%);
    }
  }
}
</style>
